import React from 'react';
import styled from 'styled-components';

import starIcon from '../../../assets/images/reviews/star.svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
SwiperCore.use([Pagination, Navigation]);

const Wrapper = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 5vh 0;

    background-color: whitesmoke;

    .myswiper {
        margin-top: 2.5vh;
        padding: 15px 0 75px 0;
        width: 95%;
    }

    .swiper-button-next, 
    .swiper-button-prev {
        color: ${props => props.disabled ? '#D3D3D3' : 'var(--darkgreen)'};
        top: 90%;
    }

    .swiper-pagination{
        display: none;
    }

    .swiper-slide{
        height: auto;
    }

    .desktop{
        display: inherit;
    }

    .mobile{
        display: none;
    }
    .swiper-wrapper{
            margin-bottom: 4vh;
    }


    @media(max-width: 1000px)
    {
        padding: 5vh 2.5vw;
        padding-top: 0;

        h2{
            font-size: 28px;
            width: 65%;
            text-align: center;
        }

        .myswiper{
            padding: 25px 0;
        }

        .swiper-slide{
            display: flex;
            justify-content: center;
        }
        
        .swiper-button-prev {
            top: 50%;
            left: 0;
        }
        .swiper-button-next {
            top: 50%;
            right: 0;
        }

        .desktop {
            display: none;
        }

        .mobile {
            display: inherit;
        }
    }
`;

const ReviewCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2vh 3vh;
    background-color: white;   
    color: #2D2B2C;

    height: 100%;

    .name{
        font-family: Oswald;
    }

    .date{
        font-family: Oswald;
        color: #C4C4C4;
        font-size: 14px;
    }

    p{
        font-size: 16px;
        text-align: center;
        margin-bottom: 0;
    }

    @media(max-width: 1000px){
        width: 65vw;
        padding: 1vh 1vh;
    }
`;

const StarWrapper = styled.div`
    display: flex;
    align-items: center;

    margin-top: 15px;
`;

const ReviewsButton = styled.button`
    position: absolute;
    z-index: 2;

    bottom: 10%;
    left: 50%;

    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--darkgreen);
    border: 2px solid var(--darkgreen);
    background: none;

    width: 250px;
    padding: 15px 30px;

    font-family: Oswald;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 17px;

    transition: background-color 250ms;

    &:hover{
        cursor: pointer;
        color: var(--darkgreen);
        background-color: rgba(122, 150, 62, 0.2);
    }

    @media(max-width: 1000px){
        position: static;

        transform: none;
    }
`;

export default function TotalReviews({ reviewsData })
{
    const reviewCount = reviewsData.Single_Review.length;
    return(
        <Wrapper>
            <h2>{reviewsData?.Title}</h2>

            <Swiper
                className="myswiper desktop"
                slidesPerView={reviewCount} 
                spaceBetween={25} 
                slidesPerGroup={2} 
            >
                {reviewsData?.Single_Review.map((review, index) => {
                    const starArr = (new Array(parseInt(review?.Number_Of_Stars))).fill(0);
                    return(
                        <SwiperSlide key={index}>
                            <ReviewCard>
                                <p>{review?.Quote}</p>
                            
                                <StarWrapper>
                                    {starArr.map((_, index) => {
                                        return <img key={`star-${index}`} src={starIcon}/>
                                    })}
                                </StarWrapper>

                                <p className='name'>{review?.By}</p>

                                <p className='date'>{review?.Date}</p>
                            </ReviewCard>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

            <Swiper
                className="myswiper mobile"
                slidesPerView={1} 
                spaceBetween={25} 
                slidesPerGroup={1} 
                pagination={{
                    "clickable": true
                }} 
                navigation={true}
            >
                {reviewsData?.Single_Review.map((review, index) => {
                    const starArr = (new Array(parseInt(review?.Number_Of_Stars))).fill(0);
                    return(
                        <SwiperSlide key={index}>
                            <ReviewCard>
                                <p>{review?.Quote}</p>
                            
                                <StarWrapper>
                                    {starArr.map((_, index) => {
                                        return <img key={`star-${index}`} src={starIcon}/>
                                    })}
                                </StarWrapper>

                                <p className='name'>{review?.By}</p>

                                <p className='date'>{review?.Date}</p>
                            </ReviewCard>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </Wrapper>
    )
}