import React, { useState } from 'react';
import styled from 'styled-components';

import { FaSearch } from "react-icons/fa";

import { navigate } from 'gatsby'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    //justify-content: space-between;

    height: 500px;
    width: 50%;

    .title{
        width: 60%;
    }
`;

const SearchBar = styled.div`
    margin: 20px 0;

    display: flex;
    align-items: center;

    width: 100%;
    border-radius: 30px;
    padding-right: 10px;

    background: #F5F5F5;

    .searchbar{
        width: 100%;
        background: none;
        outline: none;
        border: none;
        font-size: max(calc(0.6vh + 0.6vw), 16px);
        font-family: Lato;

        padding: 1.5vh 2vh 1.5vh 2vh;
        //border: 1px solid rgba(109, 110, 113, 0.5);
        border-radius: 3px;
    }

    .submit{
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
        /* position: absolute;
        right: 2.5%; */

        color: rgba(109, 110, 113, 0.5);

        border: none;
        background: none;
    }

    @media(max-width: 1000px){
        width: 100%;
    }
`;

const ListOfRetailers = styled.div`
    display: flex;
    flex-direction: column;

    max-height: 65%;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #eeeeef;
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a1c654;
    }

    scrollbar-width: thin;
    scrollbar-color: #a1c654 #eeeeef;
`;

const RetailerItem = styled.div`
    margin: 15px 0;

    width: 100%;

    display: flex;
    align-items: center;
`;

const RetailerText = styled.div`
    margin-left: 125px;

    display: flex;
    flex-direction: column;

    p{
        margin: 0;
    }

    .phone{
        color: var(--darkgreen);
    }
`;

const ShopButton = styled.button`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--darkgreen);
    border: 2px solid var(--darkgreen);
    background: none;

    width: 250px;
    padding: 15px 30px;

    font-family: Oswald;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 17px;

    transition: background-color 250ms;

    .icon{
        position: absolute;
        top: 50%;
        right: 2.5%;

        transform: translateY(-50%);

        height: 35px;
        width: 35px;
    }

    &:hover{
        cursor: pointer;
        color: var(--darkgreen);
        background-color: rgba(122, 150, 62, 0.2);
    }
`;

export default function LocationList({ retailerList, getClosestCityByPostcode, setCurrentRetailer })
{
    const [loc, setLoc] = useState("");

    const onHandleSubmit = (e) => {
        e.preventDefault();
        getClosestCityByPostcode(loc);
    }

    const onHandleShopHere = (retailerID) => {
        setCurrentRetailer(retailerID);
        navigate('/menu');
    }

    return(
        <Wrapper>
            <h2 className='title'>Find Your Nearest House of Cannabis Location</h2>

            <SearchBar>
                <input
                    placeholder="Search Nearest Store By Postal Code"
                    className="searchbar"
                    type="text"
                    value={loc}
                    onChange={(e) => setLoc(e.target.value)}
                />
                <button
                    type="submit"
                    value="Submit"
                    className="submit"
                    onClick={onHandleSubmit} 
                >
                    <FaSearch/>
                </button>
            </SearchBar>

            <ListOfRetailers>
                {retailerList.map((_retailer) => {
                    return(
                        <RetailerItem key={_retailer.id}>
                            <ShopButton
                                onClick={(e) => onHandleShopHere(_retailer.id)}
                            >
                                Shop Location
                            </ShopButton>

                            <RetailerText>
                                <h4>{_retailer.name.split(" - ")[1]}</h4>
                                <p>{_retailer.address.split(",")[0]}</p>
                                <p className='phone'>{_retailer.phone}</p>
                            </RetailerText>
                        </RetailerItem>
                    )
                })}
            </ListOfRetailers>
        </Wrapper>
    )
}