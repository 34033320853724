import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/NewDesign/Hero';
import BannerInfoTypeB from '../components/Banners/BannerInfoTypeB';
import LocationsMap from '../components/NewDesign/Location/LocationsMap';
import TotalReviews from '../components/NewDesign/Reviews/TotalReviews';
import BannerInfoTypeA from '../components/Banners/BannerInfoTypeA';
import BannerTypeA from '../components/Banners/BannerTypeA';
import TripleBanner from '../components/Location/TripleBanner';
import FaqSection from '../components/FAQ/FaqSection';

// Reviews {
//     Single_Review {
//         Number_Of_Stars
//         Date
//         By
//         Quote
//     }
//     Title
// }

export default function deliveryPage({ data }) {
  const locationData = data?.locationData;
  const heroData = locationData?.DeliveryLocHero;
  const firstHero = locationData?.InfoBannerB;
  // const reviewsData = locationData?.Reviews;
  const bannerInfoTypeAData = locationData?.InfoBannerA;
  const bannerTypeAData = locationData?.BlogBanner?.article;
  const tripleBannerData = locationData?.TripleInfoBanner;
  const faqData = locationData?.FAQ;
  const bottomBannerData = locationData?.BottomBanner;

  return (
    <>
      <Hero
        largeHero={heroData?.HeroWithButton}
        rightHero={heroData?.RightHero}
      />

      <BannerInfoTypeB
        description={firstHero?.Content}
        bgImage={
          firstHero?.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
        buttonLabel={firstHero?.Button.Label}
        buttonLink={firstHero?.Button.Custom_Link_Slug}
        center
        hasGradient
      />

      <LocationsMap />

      {/* <TotalReviews
                reviewsData={reviewsData}
            /> */}

      <BannerInfoTypeA
        title={bannerInfoTypeAData?.Title}
        subtitle={bannerInfoTypeAData?.Subtitle}
        description={bannerInfoTypeAData?.Content}
        bgImage={
          bannerInfoTypeAData?.Image.localFile.childImageSharp.gatsbyImageData
        }
      />

      <BannerTypeA
        title={bannerTypeAData?.Title}
        description={bannerTypeAData?.Preview_Text}
        bannerImage={
          bannerTypeAData?.BannerImage.localFile.childImageSharp.gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />

      {/* {`/blog/${bannerTypeAData.slug}`} */}

      <TripleBanner
        title={tripleBannerData?.Title}
        banners={tripleBannerData?.Single_Triple_Info_Banner}
      />

      <FaqSection faqdata={faqData?.FAQs} title={faqData?.Title} />

      <BannerTypeA
        title={bottomBannerData?.Title}
        description=""
        bannerImage={
          bottomBannerData?.Image.localFile.childImageSharp.gatsbyImageData
        }
        link={`/${bottomBannerData?.ButtonLink}`}
        buttonLabel={bottomBannerData?.ButtonLabel}
      />
    </>
  );
}

export const query = graphql`
  query deliveryPageQuery($id: String!) {
    locationData: strapiDeliveryLocations(id: { eq: $id }) {
      DeliveryLocHero {
        RightHero {
          ButtonLabel
          ButtonText
          Subtitle
          Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        HeroWithButton {
          ButtonLabel
          ButtonText
          Subtitle
          Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      InfoBannerB {
        Content
        Button {
          Custom_Link_Slug
          Label
        }
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }

      InfoBannerA {
        Content
        Title
        Subtitle
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      BlogBanner {
        article {
          Preview_Text
          slug
          Title
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      TripleInfoBanner {
        Title
        Single_Triple_Info_Banner {
          Content
          Header
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
      FAQ {
        FAQs {
          Answer
          Question
          id
        }
        Title
      }
      BottomBanner {
        ButtonLabel
        ButtonLink
        Title
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
