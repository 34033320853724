import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { CheckoutContext } from 'contexts/checkout-context';

//For calculating distance
import Geocode from 'react-geocode';

import getDistance from 'geolib/es/getDistance';
import LocationList from './LocationList';
import GoogleMap from './GoogleMap';
import NewMap from './NewMap';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;

    background: white;

    max-height: 550px;
    width: 100%;
    
    padding: 2.5%;

    @media(max-width: 1000px)
    {
        display: none;
    }
`;

export default function LocationsMap({ selectedRetailer='882f4295-2c51-4441-97ee-762a8b9e3478' })
{
    const { retailerList, setCurrentRetailer } = useContext(CheckoutContext);

    const [location, setLocation] = useState(null);
    const [sortedlist, setSortedList] = useState([]);
    const [selectedretailerdata, setSelectedRetailerData] = useState(null);

    useEffect(() => {
        if (navigator.geolocation) {
            try{
                navigator.geolocation.getCurrentPosition(getCoordsFromWindow, () => setSortedList(retailerList || []));
            }
            catch(err){
                console.log("failed sorting ", err)
            }
        } 
        else {
            setSortedList(retailerList || []);
        }
    }, [retailerList]);

    useEffect(() => {
        if(location) handleSortingRetailers(location)
    }, [location])

    useEffect(() => {
        if(retailerList && selectedRetailer)
        {
            const _retailerData = retailerList.find((_retailer) => _retailer.id === selectedRetailer);
            setSelectedRetailerData(_retailerData);
        }
    }, [retailerList, selectedRetailer])

    const getClosestCityByPostcode = async postcode => {
        Geocode.setApiKey("AIzaSyDHdRRXlkEtxxkihYsWmGqwt7SdTenC-EI");
        return Geocode.fromAddress(`${postcode},CA`).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            const userPos = {
                latitude: lat,
                longitude: lng
            }
            setLocation(userPos);
          },
          (error) => {
            console.error(error);
            return null;
          }
        );
    }

    const getCoordsFromWindow = (userPositionData) => {
        if(userPositionData)
        {
            const userPos = {
                latitude: userPositionData.coords.latitude,
                longitude: userPositionData.coords.longitude
            }
            setLocation(userPos);
        } 
    }

    const handleSortingRetailers = (userPos) => {
        if(userPos && retailerList)
        {
            var newRetailerList = retailerList?.map((_retailer) => {
                //Calculating retailer distance in km
                const retailerDistance = getDistance(
                    userPos,
                    {latitude: _retailer.coordinates.longitude, 
                    longitude: _retailer.coordinates.latitude}
                ) / 1000;

                return({
                    ..._retailer,
                    distance: retailerDistance
                })
            });

            setSortedList(newRetailerList.sort((a, b) => a.distance - b.distance))
        }
        else
        {
            setSortedList(retailerList || [])
        }
    }

    return(
        <Wrapper>
            <NewMap
                selectedRetailer={selectedretailerdata}
                retailerList={retailerList}
            />
            
            <LocationList
                retailerList={sortedlist}
                getClosestCityByPostcode={getClosestCityByPostcode}
                setCurrentRetailer={setCurrentRetailer}
            />
            {sortedlist.map((item) => {<>{item}</>})}
        </Wrapper>
    )
}