import React, {useContext} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby'

import BackgroundImage from 'gatsby-background-image';
//For Converting To Background Image
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import ModalsContext from '../../contexts/ModalsContext';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;

    height: 650px;
    width: 100%;

    padding: 10px;

    .hoc-button{
        width: fit-content;
        padding: 2rem 15rem;
    }

    .bg-image,
    .bg-image::after,
    .bg-image::before{
        border-radius: 10px;
    }

    @media(max-width: 1000px){
        flex-direction: column;
        align-items: center;
        height: auto;

        .hoc-button{
            padding: 10px 20px;
            width: 100%;
        }
    }
`;

const LargeHero = styled(BackgroundImage)`
    width: 64.5%;
    padding: 12.5% 3% 5% 3%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 10px;
    overflow: hidden;
    
    .title{
        color: #F6F6F6;
        font-size: 62px;
        width: 65%;
    }

    .subtitle{
        color: #F6F6F6;
        font-size: 24px;
        width: 60%;
    }

    @media(max-width: 1000px){
        width: 100%;
        height: 80vh;
        max-height: 600px;

        padding: 20% 5% 25% 5%;

        .title{
            font-size: 36px;
            width: 85%;
        }

        .subtitle{
            font-size: 20px;
            width: 85%;
        }
    }
`;

const SmallHero = styled(BackgroundImage)`
    width: 34.5%;

    padding: 12.5% 3% 5% 3%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-radius: 10px;    

    .title{
        font-size: 62px;
        width: 65%;
    }

    .subtitle{
        font-size: 24px;
        width: 60%;
    }

    button{
        display: flex;
        justify-content: center;
        font-size: 15px;
        width: 100% !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    @media(max-width: 1000px){
        width: 100%;
        margin-top: 20px;
        height: 600px;
        padding: 55% 5% 55% 5%;

        .title{
            font-size: 36px;
            width: 85%;
        }

        .subtitle{
            font-size: 24px;
            width: 85%;
        }

        button{
            font-size: 15px;
        }
    }
`;

export default function Hero({ largeHero, rightHero })
{
    const largeImage = getImage(largeHero?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const largeBgImage = convertToBgImage(largeImage);

    const rightImage = getImage(rightHero?.Image?.localFile?.childImageSharp?.gatsbyImageData);
    const rightBgImage = convertToBgImage(rightImage);

    const { isModalOpen, setIsModalOpen } = useContext(ModalsContext);

    return(
        <Wrapper>
            <LargeHero
                {...largeBgImage}
                className='bg-image'
            >
                <h2 className='title'>{largeHero?.Title}</h2>
                <h2 className='subtitle'>{largeHero?.Subtitle}</h2>
                <Link to={'/menu'}>
                    <button className='hoc-button'>{largeHero?.ButtonLabel}</button>
                </Link>
            </LargeHero>

            <SmallHero
                {...rightBgImage}
                className='bg-image'
            >
                <h2 className='title'>{rightHero?.Title}</h2>
                <h2 className='subtitle'>{rightHero?.Subtitle}</h2>
                <button className='hoc-button' onClick={()=>{setIsModalOpen(!isModalOpen)}}>{rightHero?.ButtonLabel}</button>
            </SmallHero>
        </Wrapper>
    )
}