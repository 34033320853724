import React, { useRef, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react';

import styled from 'styled-components';

import Marker from './Marker';


// import { Wrapper, Status } from "@googlemaps/react-wrapper";

const StyledWrapper = styled.div`
    height: 100%;
    width: 50%;
`;

// function Map({ children, center, zoom })
// {
//     const ref = useRef();
//     const [map, setMap] = useState();

//     useEffect(() => {
//         if (typeof window !== "undefined" && window != 'undefined'){
//             if (ref.current && !map) {
//             setMap(new window.google.maps.Map(ref.current, { center, zoom }));
//             }
//         }
//     }, [ref, map]);
  

//     return (
//         <>
//             <div ref={ref} id="map" style={{ height: 500, width: '90%' }}/>
//             {React.Children.map(children, (child) => {
//                 if (React.isValidElement(child)) {
//                 // set the map prop on the child component
//                 return React.cloneElement(child, { map });
//                 }
//             })}
//         </>    
//     );
//   }

export default function NewMap({ selectedRetailer, retailerList })
{
    // const DEFAULT_CENTER = { lat: (selectedRetailer?.coordinates?.longitude || 43.653908), 
    //     lng: (selectedRetailer?.coordinates?.latitude || -79.384293)};
    // const DEFAULT_ZOOM = 15;

    return(
        <>
            {/* <StyledWrapper>
                <Wrapper apiKey='AIzaSyDHdRRXlkEtxxkihYsWmGqwt7SdTenC-EI'>
                    <Map center={DEFAULT_CENTER} zoom={DEFAULT_ZOOM}>
                        {retailerList?.map((retailer) => {
                            return <Marker key={retailer.id} position={{ lat: retailer?.coordinates?.longitude || 0, lng: retailer?.coordinates?.latitude || 0 }}/>
                        })}
                    </Map>
                </Wrapper>
            </StyledWrapper> */}
        </>
    )
}