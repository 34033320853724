import React, { useState } from 'react'
import GoogleMapReact from 'google-map-react';

import styled from 'styled-components';

const Wrapper = styled.div`
    height: 500px;
    width: 500px;
`;

const DEFAULT_CENTER = {
    center: {
        lat: 43.2583735,
        lng: -79.8744884,
    }
}

const Marker = () => {
    return(<div>Here</div>)
}

export default function GoogleMap({ location })
{
    return(
        <Wrapper>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDHdRRXlkEtxxkihYsWmGqwt7SdTenC-EI' }}
                center={DEFAULT_CENTER}
                zoom={15}
                yesIWantToUseGoogleMapApiInternals
            >
                <Marker
                    lat={43.2583735}
                    lng={-79.8744884}
                />

            </GoogleMapReact>
        </Wrapper>
    )
}